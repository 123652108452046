import React from "react"
import ElizabethQuayBGImage from "../components/ElizabethQuayBGImage"
import Navigation from "../components/Navigation"
import CBTContent from "../components/content/CBTContent"
import SchemaContent from "../components/content/SchemaContent"
import EFTContent from "../components/content/EFTContent"
import DBTContent from "../components/content/DBTContent"
import MindfulnessContent from "../components/content/MindfulnessContent"
import CFTContent from "../components/content/CFTContent"
import Layout from "../components/Layout"
import { Link } from "gatsby"

export default function () {
  return (
    <Layout>
      <ElizabethQuayBGImage
        style={{
          backgroundPosition: `0% 30%`,
        }}
      >
        <div className="hero-image-canvas"></div>
      </ElizabethQuayBGImage>

      <div className="contents-wrapper">
        <div className="text-container">
          <h1 id="approach-head">Therapeutic approach</h1>
        </div>
        <div className="text-container">
          <ul className="hanging-indent-list">
            <li>
              <i className="fa fa-chevron-right"></i>
              <Link to="#cbt">Cognitive Behavioural Therapy</Link>
            </li>
            <li>
              <i className="fa fa-chevron-right"></i>
              <Link to="#schema">Schema Therapy</Link>
            </li>
            <li>
              <i className="fa fa-chevron-right"></i>
              <Link to="#eft">Emotionally Focused Therapy for Couples</Link>
            </li>
            <li>
              <i className="fa fa-chevron-right"></i>
              <Link to="#dbt">Dialectical Behavioural Therapy</Link>
            </li>
            <li>
              <i className="fa fa-chevron-right"></i>
              <Link to="#mindfulness">Mindfulness</Link>
            </li>
          </ul>
        </div>
        <div className="text-container">
          <CBTContent />
          <div>
            <Link to="#approach-head" className="link-top">^ top </Link>
          </div>
          <SchemaContent />
          <div>
            <Link to="#approach-head" className="link-top">^ top </Link>
          </div>
          <EFTContent />
          <div>
            <Link to="#approach-head" className="link-top">^ top </Link>
          </div>
          <DBTContent />
          <div>
            <Link to="#approach-head" className="link-top">^ top </Link>
          </div>
          <MindfulnessContent />
          <div>
            <Link to="#approach-head" className="link-top">^ top </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
