import React from "react"
import ThoughtBubbleBoy from "../imageComponents/ThoughtBubbleBoyImg"
import JournalImg from "../imageComponents/JournalImg"

export default function CBTContent() {
  return (
    <>
      <div className="therapy-flex">
        <div className="text-container">
          <h2 id="cbt">Cognitive Behavioural Therapy (CBT)</h2>
          <p>
            Cognitive-Behavioural Therapy (CBT) is a form of therapy that has
            proven to be highly effective for a wide range of problems. It is
            particularly helpful for depression and anxiety disorders.
          </p>
          <p>
            CBT is based on the idea that the way we think, feel and behave are
            interrelated. It explores how patterns of thoughts, feelings and
            behaviours might inadvertently maintain current psychological
            distress.
          </p>
          <p>
            The therapist and client work together to develop a treatment plan
            to achieve the client's goals.
          </p>
          <p>Treatment will usually include:</p>

          <ul className="hanging-indent-list">
            <li>
              <i className="fa fa-chevron-right"></i>
              psychoeducation about the client's difficulties
            </li>
            <li>
              <i className="fa fa-chevron-right"></i>
              learning how to identify and challenge unhelpful thoughts and
              assumptions
            </li>
            <li>
              <i className="fa fa-chevron-right"></i>
              reduction of avoidant behaviour by gradual exposure to fear
              situations (with the support of the therapist)
            </li>
            <li>
              <i className="fa fa-chevron-right"></i>
              learning new problem solving skills</li>
          </ul>

          <p>
            CBT can help clients to:
            <ul className="hanging-indent-list">
              <li>
                <i className="fa fa-chevron-right"></i>
                feel more confident in their ability to cope with difficult
                situations
              </li>
              <li>
                <i className="fa fa-chevron-right"></i>
                reduce symptoms of anxiety or depression
              </li>
              <li>
                <i className="fa fa-chevron-right"></i>
                increase positive beliefs about themselves, others and the world
              </li>
              <li>
                <i className="fa fa-chevron-right"></i>
                increase their sense of personal wellbeing
              </li>
            </ul>
          </p>
        </div>
        <div className="image-padding">
          <div className="complementary-image-wrapper">
            <JournalImg style={{}} className={"complementary-image"} />
          </div>
        </div>
      </div>
    </>
  )
}
