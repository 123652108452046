import React from "react"
import TeddyBearImg from '../imageComponents/TeddyBearImg'

export default function SchemaContent() {
  return (
    <div className="therapy-flex">
      <div className="image-padding">
        <div className="complementary-image-wrapper">
          <TeddyBearImg style={{}} className={"complementary-image"} />
        </div>
      </div>
      <div className="text-container">
        <h2 id="schema">Schema Therapy</h2>
        <p>
          Schema Therapy integrates elements of cognitive-behavioural,
          experiential, interpersonal, and psychoanalytic therapies into a
          single therapeutic approach.
        </p>

        <p>
          It was initially created by Dr Jeff Young, who found a subset of
          clients that did not find standard cognitive therapy effective. These
          clients had long-term patterns of thinking or feeling about themselves
          and the world that required a different approach.
        </p>
        <p>
          Schema Therapy is a more moderate-to-long term therapy. It is
          particularly effective in helping individuals with more complex,
          long-standing difficulties. Schema Therapy focuses on treating the
          cause of problems and not the symptoms.
        </p>
        <p>
          It focuses on identifying emotional needs that might have gone unmet
          for the client when they were younger, and how this may have
          contributed to patterns of unhelpful behaviours over a client’s
          lifetime.
        </p>
        <p>
          Sarah has been using schema therapy for many years, and is currently
          undertaking training to become a Certified Schema Therapist.
        </p>
      </div>
    </div>
  )
}
