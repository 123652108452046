import React from "react"
import WaterDroplet from "../imageComponents/WaterDroplet"

export default function DBTContent() {
  return (
    <div className="therapy-flex">
      <div className="image-padding">
        <div className="complementary-image-wrapper">
          <WaterDroplet style={{}} className={"complementary-image"} />
        </div>
      </div>
      <div className="text-container">
        <h2 id="dbt">Dialectical Behavioural Therapy (DBT)</h2>

        <p>
          Dialectical Behaviour Therapy is a type of treatment that was
          initially developed for people with a diagnosis of Borderline
          Personality Disorder (BPD).
        </p>

        <p>In its truest form, DBT has four components comprising:</p>
        <ul className="hanging-indent-list">
          <li>
            <i className="fa fa-chevron-right"></i>
            weekly individual sessions
          </li>
          <li>
            <i className="fa fa-chevron-right"></i>
            skills training (group format)
          </li>
          <li>
            <i className="fa fa-chevron-right"></i>
            phone consultations
          </li>
          <li>
            <i className="fa fa-chevron-right"></i>
            consult team for the therapists
          </li>
        </ul>
        <p>
          As its name suggests, DBT focuses on dialectics - which means
          synthesising or integrating opposite ends of a spectrum - such as the
          question "How can I accept that my life is currently very hard AND
          strive for change at the same time".
        </p>

        <p>
          People with BPD reported they felt that traditional cognitive therapy
          could be invalidating of their feelins. On the other hand, counselling
          was validating of their feelings but didn't help them change unhelpful
          behaviours.
        </p>
        <p>
          DBT aims to help validate a person's experience and help them to make
          changes to unhelpful patterns of behaviour.
        </p>

        <p>Nowadays, DBT is used to help a variety of types of problem.</p>
        <p>
          The skills focus on mindfulness, interpersonal effectiveness, distress
          tolerance and emotion regulation.
        </p>
        <p>
          Some limitations come with not working as part of a larger team in
          Private Practice, and as a result Sarah cannot offer DBT in its truest
          form.
        </p>
        <p>
          However, she is able to offer therapy informed by DBT, which can utilise
          many of the skills outlined above.
        </p>
        <p>
          These have proven particularly effective when combined with access to
          group therapy from other service providers (eg Perth Clinic or the
          Marian Centre).
        </p>
      </div>
    </div>
  )
}
