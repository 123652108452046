import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import BackgroundImage from 'gatsby-background-image';

const getImage = graphql`
{
    fluidImage: file(relativePath: {eq: "scenic-and-iconic-elizabeth-quay-bridge-at-sunset--8BX4NBA.jpg"}) {
        id
        childImageSharp {
            fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid
            }
        }
      }
    }
`

export default ({ children, style }) => {
    const { fluidImage: { childImageSharp: { fluid: image } } } = useStaticQuery(getImage);

    const colorDarkChocolate = `119, 79, 56`;
    const colorLightPeach = `224, 141, 121`;

    const imageStack = [
        `linear-gradient(to right bottom, rgba(${colorDarkChocolate}, .3), rgba(${colorLightPeach}, .3))`,
      image,
    ]

  return (
    <>
      <div>
        <BackgroundImage
          Tag="section"
          style={style}
          fluid={imageStack}
        >
          {children}
        </BackgroundImage>
      </div>
    </>
  )
}