import React from "react"
import MeditationOnBeachImg from "../imageComponents/MeditationOnBeachImg"

export default function MindfulnessContent() {
  return (
    <div className="therapy-flex">
      <div className="text-container">
        <h2 id="mindfulness">Mindfulness</h2>
        <p>
          John Kabat Zinn has described mindfulness as paying attention in a
          particular way: on purpose, in the present moment, and
          non-judgmentally.
        </p>
        <p>
          As we develop mindfulness, we learn to have an awareness of our
          thoughts and bodily sensations without getting caught up in them.
        </p>
        <p>
          This can help us to develop a new relationship with our own internal
          (and external) experience, helping a person to become more
          compassionate, accepting and gentle.
        </p>
        <p>
          We can learn to be more intentional about our responses and life
          choices, and break free from unhelpful reactions learnt through habit.
        </p>
        <p>
          People who learn to incorporate mindfulness into their daily lives
          often find they become more relaxed and that their mood improves.
        </p>
        <p>
          Mindfulness has been shown to be effective in helping with
          psychological problems such as recurrent depression, anxiety and
          physical pain.
        </p>
      </div>
      <div className="image-padding">
        <div className="complementary-image-wrapper">
          <MeditationOnBeachImg style={{}} className={"complementary-image"} />
        </div>
      </div>
    </div>
  )
}
