import React from "react"
import HoldingHands from "../imageComponents/HappyCoupleImg"
import HoldingHandsImg from "../imageComponents/HoldingHandsImg"

export default function EFTContent() {
  return (
    <div className="therapy-flex">
      <div className="text-container">
        <h2 id="eft">Emotionally Focused Therapy for Couples (EFT)</h2>

        <p>
          EFT is an evidenced based therapy that focuses on adult relationships
          and a couple's ability to develop a healthy emotional attachment.
        </p>
        <p>
          The therapist helps couples to identify negative patterns in their
          relationship and to develop a safer connection to each other.
        </p>
        <p>
          This has proven to increase relationship satisfaction, intimacy,
          closeness and positive patterns of interacting.
        </p>
        <p>
          The therapy focuses on emotion as one of the key ingredients that
          affects an individual's internal experience and the interactions
          within their relationship.
        </p>

        <p>
          Sarah first started her training in EFT a number of years ago, and
          really enjoys seeing couples grow closer together and develop a
          stronger bond.
        </p>
        <p>
          She is currently undertaking ongoing training to become certified in
          Emotionally Focused Therapy.
        </p>
      </div>

      <div className="image-padding">
        <div className="complementary-image-wrapper">
          <HoldingHandsImg style={{}} className={"complementary-image"} />
        </div>
      </div>
    </div>
  )
}
